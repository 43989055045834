export const URLS = {
  employeesLogin: '/employees/login',
  employeesTokenRefresh: '/employees/tokens/refresh',
  bpmCalculations: '/admin/bpm-calculator/calculations',
  bpmCalculationsSearch: '/admin/bpm-calculator/calculations/search',
  lovBranchesSearch: '/lov/branches/search',
  slotsSearch: (branchId: string) =>
    `/admin/appointments/slots/for-branch/${branchId}/search`,
  customersSearch: '/admin/customers/search',
  customerDetail: (customerId?: string) => `/admin/customers/${customerId}`,
  opportunitiesSearch: '/admin/opportunities/search',
  createOrUpdateCar: (opportunityId: string) =>
    `/admin/opportunities/cars/for-opportunity/${opportunityId}`,
  getAdminOpportunityDetail: (opportunityId?: string) =>
    `/admin/opportunities/${opportunityId}`,
  cancelAdminOpportunity: (opportunityId: string) =>
    `/admin/opportunities/${opportunityId}/cancel`,
  reopenAdminOpportunity: (opportunityId: string) =>
    `/admin/opportunities/${opportunityId}/reopen`,
  pathOpportunity: (opportunityId: string) =>
    `/admin/opportunities/${opportunityId}`,
  createAppointment: '/admin/opportunities/at-appointment',
  updateAppointment: (opportunityId?: string) =>
    `/admin/opportunities/${opportunityId}/appointment`,
  customerCreate: '/admin/customers',
  customerUpdate: (customerId?: string) => `/admin/customers/${customerId}`,
  customerOpportunityAssign: (opportunityId: string) =>
    `/admin/opportunities/${opportunityId}/assign-customer`,
  opportunityDetail: (opportunityId?: string) =>
    `/admin/opportunities/${opportunityId}`,
  opportunityNotesSearch: `/admin/opportunities/notes/search`,
  opportunityNote: (opportunityId: string) =>
    `/admin/opportunities/${opportunityId}/notes`,
  opportunitiesSellingSearch: `/admin/opportunities/selling/search`,
  employeesSearch: '/employees/search',
  ordersSearch: '/admin/orders/search',
  carSearch: '/admin/cars/search',
  orderDetail: (orderId?: string) => `/admin/orders/${orderId}`,
  orderDeliver: (orderId: string) => `/admin/orders/${orderId}/deliver`,
  orderNotes: '/admin/orders/notes/search',
  orderNoteCreate: (orderId: string) =>
    `/admin/orders/notes/for-order/${orderId}`,
  orderPathDeliveryAppointment: (deliveryAppointmentId: string) =>
    `/admin/orders/delivery-appointments/${deliveryAppointmentId}`,
  bookingUnavailabilityCreate: '/admin/appointments/booking-unavailability',
  bookingUnavailability: (bookingUnavailabilityId: string) =>
    `/admin/appointments/booking-unavailability/${bookingUnavailabilityId}`,
  reclamationCreate: (orderId: string) => `/admin/orders/${orderId}/claim`,
  dailyReport: (branchId?: string) =>
    `/admin/opportunities/daily-report${
      branchId ? `?branchId=${branchId}` : ''
    }`
}

export const UNKNOWN_VALUE = 'UNKNOWN_VALUE_FE_ONLY'
